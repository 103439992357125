import React, { useState, useEffect, useCallback } from "react";

import "./App.css";
import Map from './components/maplibre/Map';
import { poisConfigArray } from "./config/poisConfig";



function filterGeojson(geojson, type) {
  let newGeojson = JSON.parse(JSON.stringify(geojson));
  newGeojson.features = geojson.features.filter(
    (item) => item.properties.type === type
  );
  return newGeojson;
}

function App() {


  useEffect(() => {

    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["setCookieDomain", "*.bikegenoss.ch"]);
    _paq.push(["setDomains", ["*.bikegenoss.ch", "*.bikekodex.ch", "*.map.bikegenoss.ch", "*.map.bikegenossenschaft.ch"]]);
    _paq.push(["enableCrossDomainLinking"]);
    _paq.push(["disableCookies"]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = "https://analytics.bikegenossenschaft.ch/";
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '5']);
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
    })();
  }, []);

  const [data, setData] = useState();

  const documentHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', documentHeight)
  documentHeight()

  const fetchData = useCallback(async () => {
    const response = await fetch(
      "https://map.bikegenoss.ch/api/api.php"
    );
    const response_text = await response.json();
    const json = JSON.parse(response_text[0]);



    let pois = {};
    poisConfigArray.forEach((item) => {
      pois[item.id] = filterGeojson(json, item.type);
    })
    pois['touren'] = JSON.parse(response_text[1]);
    pois['transport'] = JSON.parse(response_text[2]);
    setData(pois);

  }, []);

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  return <div>
    <Map data={data} />

  </div>;
}

export default App;
