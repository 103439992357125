import { useEffect } from "react";

export default function TrackLayer(props) {
    const { data, map, layer } = props;
    const { id, defaultChecked, paint } = layer;

    useEffect(() => {
        const updatedFeatures = data.features.map((feature) => {
            return {
                ...feature,
                id: feature.properties.id
            }
        });
        const updatedData = {
            ...data,
            features: updatedFeatures
        }

        map.current.on("load", function () {
            map.current.addSource(id, {
                type: "geojson",
                data: updatedData,
            });
            map.current.addLayer({
                id: id,
                type: "line",
                source: id,
                layout: {
                    "visibility": defaultChecked ? 'visible' : 'none',
                    "line-sort-key": 0
                },
                'paint': paint
            });
        });
    });


    return null;
}