const poisConfigArray = [
    { 'id': 'shops', 'name': 'Shops', 'singleName': 'Shop', 'type': 0, 'defaultChecked': false, 'icon': 'tools@2x.png' },
    { 'id': 'hotels', 'name': 'Hotels', 'singleName': 'Hotel', 'type': 1, 'defaultChecked': true, 'icon': 'hotel1@2x.png' },
    { 'id': 'pumptracks', 'name': 'Pumptracks', 'singleName': 'Pumptrack', 'type': 2, 'defaultChecked': true, 'icon': 'trailpark@2x.png' },
    { 'id': 'anlage', 'name': 'Anlage', 'singleName': 'Anlage', 'type': 3, 'defaultChecked': false, 'icon': 'trailpark4@2x.png' },
    { 'id': 'ladestationen', 'name': 'Ladestationen', 'singleName': 'Ladestation', 'type': 4, 'defaultChecked': false, 'icon': 'energy@2x.png' }
]
const paintTouren = {
    'line-color': '#db1e2a',
    'line-width': 3,
    'line-opacity': 0.8,
    'line-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        '#EA2B1F',
        '#499F68'
    ]
}

const paintTransport = {
    'line-color': 'blue',
    'line-width': 3,
    'line-opacity': 0.75,
    'line-dasharray': [4, 2, 8, 2]
}

const trackConfigArray = [
    { 'id': 'touren', 'name': 'Touren', 'singleName': 'Tour', 'type': 0, 'defaultChecked': true, 'paint': paintTouren },
    { 'id': 'transport', 'name': 'Transport', 'singleName': 'Transport', 'type': 0, 'defaultChecked': true, 'paint': paintTransport },

]

let idToName = {}
trackConfigArray.forEach((item) => {
    idToName[item.id] = item.singleName
})
poisConfigArray.forEach((item) => {
    idToName[item.id] = item.singleName
})

let layersArray = [];
trackConfigArray.forEach((item) => {
    layersArray.push(item.id)
})
poisConfigArray.forEach((item) => {
    layersArray.push(item.id)
})


export { poisConfigArray, trackConfigArray, idToName, layersArray }