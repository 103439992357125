import { useState, useCallback, useEffect } from 'react'
import './bottom-banner.scss';
import { idToName, layersArray } from '../../../config/poisConfig';
import turf from 'turf'


import { ReactComponent as CloseIcon } from './Close_square.svg';
import { set } from 'react-ga';


export default function BottomBanner(props) {
    const { map } = props;
    const [feature, setFeature] = useState(null);
    const [banner, setBanner] = useState(null);

    const [currentlySelectedFeature, setCurrentlySelectedFeature] = useState(null);

    const fetchData = useCallback(async (id) => {
        if (id === undefined) {
            setBanner(null);
            return;
        }
        if (id === undefined) return;
        const response = await fetch(
            "https://bikegenoss.ch/wp-json/bikegenoss-api/v1/posts/?ids=" + id
        );
        const response_text = await response.json();
        setBanner(response_text);

    }, []);

    useEffect(() => {
        if (map.current === null) return;

        const handleMapLoad = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            setCurrentlySelectedFeature(id);

            // Wait for both style and source to be loaded
            const waitForSourceLoad = () => {
                return new Promise((resolve) => {
                    // Check if source is already loaded
                    if (map.current.isSourceLoaded(layersArray[0])) {
                        resolve();
                        return;
                    }

                    // If not loaded, wait for sourcedata event
                    const sourceLoadHandler = (e) => {
                        if (e.isSourceLoaded) {
                            map.current.off('sourcedata', sourceLoadHandler);
                            resolve();
                        }
                    };

                    map.current.on('sourcedata', sourceLoadHandler);
                });
            };

            // First wait for source to load
            await waitForSourceLoad();

            // Add a small delay to ensure rendering is complete
            await new Promise(resolve => setTimeout(resolve, 100));

            // Now query the features
            const features = map.current.queryRenderedFeatures({ layers: layersArray });

            const filteredFeatures = features.filter(feature => {
                return feature.properties && feature.properties.wp_id === parseInt(id);
            });

            if (filteredFeatures.length > 0) {
                const hoveredStateId = filteredFeatures[0].id;

                map.current.setFeatureState(
                    { source: filteredFeatures[0].layer.source, id: hoveredStateId },
                    { active: true }
                );

                setFeature(filteredFeatures[0]);
                await fetchData(filteredFeatures[0].properties.wp_id);

                const bounds = turf.bbox(filteredFeatures[0].geometry);
                map.current.fitBounds(bounds, { padding: 50 });
            }
        };

        if (map.current.isStyleLoaded()) {
            handleMapLoad().catch(console.error);
        } else {
            const loadHandler = () => {
                handleMapLoad().catch(console.error);
            };
            map.current.on('load', loadHandler);
            return () => {
                map.current.off('load', loadHandler);
            };
        }
    }, [map.current]);

    useEffect(() => {
        let tmpCLickedFeature = null;
        map.current.on('click', (e) => {

            let width = 20;
            let height = 20;

            let clickedFeatures = map.current.queryRenderedFeatures([
                [e.point.x - width / 2, e.point.y - height / 2],
                [e.point.x + width / 2, e.point.y + height / 2]
            ], { layers: layersArray });

            if (feature !== null) {
                let _paq = window._paq = window._paq || [];
                _paq.push(['trackEvent', 'Map', 'Map Click', feature?.properties?.name])

                map.current.setFeatureState(
                    { source: feature.layer.source, id: feature.id },
                    { active: false }
                );
            }

            if (clickedFeatures.length === 0) {
                console.log('no features')
                setBanner(null);
                setFeature(null);
                return;
            }

            let hoveredStateId = clickedFeatures[0].id;
            map.current.setFeatureState(
                { source: clickedFeatures[0].layer.source, id: hoveredStateId },
                { active: true }
            );
            tmpCLickedFeature = clickedFeatures[0];
            setFeature(clickedFeatures[0]);
            fetchData(clickedFeatures[0].properties.wp_id).catch(console.error)
        })
    }, [fetchData, map, feature]);

    const linkClick = () => {
        let _paq = window._paq = window._paq || [];
        _paq.push(['trackEvent', 'Map', 'Map Link Click', feature.properties.name])
    }

    const closeBottombanner = (event) => {
        event.preventDefault();
        event.stopPropagation()
        setBanner(null);
        setFeature(null);
        map.current.setFeatureState(
            { source: feature.layer.source, id: feature.id },
            { active: false }
        );
    }

    return (<div id="pop-up">
        {banner && feature ?
            <div className="pop-up-content">
                <div className="pop-up__image">
                    <img alt="" src={banner.thumbnail}></img>
                </div>
                <div className="pop-up__text">
                    <a className="pop-up__link" href={banner.permalink} target="_blank" rel="noreferrer" onClick={linkClick}>
                        <div className="pop-up__tag">{idToName[feature.layer.id]}</div>
                        <div className="pop-up__title">{feature.properties.name}</div>
                        <div className="pop-up__excerpt"></div>
                        {banner.permalink ? <img className="pop-up__link-arrow" src={process.env.PUBLIC_URL + '/assets/arrow-link.svg'} alt="arrow-link" /> : null}
                        <div className="close-icon" onClick={closeBottombanner}>

                            <CloseIcon />

                        </div>
                    </a>
                </div>
            </div> : ''
        }
    </div >);
}
