import React, { useState, useRef, useEffect } from "react";
// eslint-disable-next-line
import maplibregl from "!maplibre-gl"; // ! is important here
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";
import MarkerLayer from './MarkerLayer'
import TrackLayer from "./TrackLayer";
import LayerSwitcher from "./LayerSwitcher";
import "./map.scss";
import { poisConfigArray, trackConfigArray } from '../../config/poisConfig'
import BottomBanner from "./bottom-banner/BottomBanner";
import 'maplibre-gl/src/css/maplibre-gl.css';
maplibregl.workerClass = maplibreglWorker;

export default function Map(props) {

  const { data } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);


  const [lng, setLng] = useState(8.433071117939605);
  const [lat, setLat] = useState(46.97564153438015);
  const [zoom] = useState(10);



  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style:
        "https://api.maptiler.com/maps/ch-swisstopo-lbm/style.json?key=kBczIQHgnFMWlsd7Ygx7",
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    const currentUrl = window.location.href;
    const queryString = currentUrl.split('?')[1];
    const params = new URLSearchParams(queryString);
    const coord = params.get('coord');
    if (coord) {
      const [lngCoord, latCoord] = coord.split(',');
      setLng(parseFloat(lngCoord));
      setLat(parseFloat(latCoord));
      map.current.fitBounds([[latCoord, lngCoord], [latCoord, lngCoord]], {
        padding: 50,
        maxZoom: 15,
      });
    }
  }, []);

  return (
    <div className="map-wrap" id="map-wrap">
      <LayerSwitcher map={map}></LayerSwitcher>
      <div ref={mapContainer} className="map" />

      {
        data ? trackConfigArray.map((item) => {
          return (<TrackLayer key={item.id} data={data[item.id]} map={map} layer={item} />);
        }) : null
      }
      {
        data ? poisConfigArray.map((item) => {
          return (<MarkerLayer key={item.id} data={data[item.id]} map={map} layer={item} />);
        }) : null
      }
      {map.current ? <BottomBanner map={map} /> : null}

    </div>
  );
}
