import './layer-switcher.scss'

import React, { useState } from 'react'
import { poisConfigArray, trackConfigArray } from '../../config/poisConfig'


export default function LayerSwitcher(props) {
  const { map } = props;
  const [hamburger, setHamburger] = useState(false);
  const handleChange = (e) => {
    map.current.setLayoutProperty(e.target.id, 'visibility', e.target.checked ? 'visible' : 'none');
  }
  const hamburgerHandleChange = () => {
    setHamburger(current => !current)
  }
  return (

    <nav className="nav-filter">

      <div className="row" id="hamburger"> <input onChange={hamburgerHandleChange} type="checkbox" id="hamburg" /> <label htmlFor="hamburg" className="hamburg" > <span className="line"></span> <span className="line"></span> </label> </div>

{hamburger ? <div className="nav-filter__items">
      {
        poisConfigArray.map((item) => {
          return ([<input key={'input' + item.id} type="checkbox" defaultChecked={item.defaultChecked} id={item.id} onChange={handleChange} />,
          <label key={'label' + item.id} htmlFor={item.id}>{item.name}<span className="unchecked-label"><img src={process.env.PUBLIC_URL + '/assets/arrow-filter.svg'} alt="arrow-filter" /></span><span className="checked-label"><img src={process.env.PUBLIC_URL + '/assets/checkmark-filter.svg'} alt="checkmark-filter" /></span></label>]);
        })
      }
      {
        trackConfigArray.map((item) => {
          return ([<input key={'input' + item.id} type="checkbox" defaultChecked={item.defaultChecked} id={item.id} onChange={handleChange} />,
          <label key={'label' + item.id} htmlFor={item.id}>{item.name}<span className="unchecked-label"><img src={process.env.PUBLIC_URL + '/assets/arrow-filter.svg'} alt="arrow-filter" /></span><span className="checked-label"><img src={process.env.PUBLIC_URL + '/assets/checkmark-filter.svg'} alt="checkmark-filter" /></span></label>]);
        })
      } </div> : null}
    </nav>
  )
}